.workshopContainer {
    font-family: 'Inter';
    background-color: #333;
    /* margin: 0 0.5vw; */
    padding: 0;
    text-align: left;
    color: #fff;
    max-height: 100vh;
  }
  
  .header {
    background-color: #000;
    position: fixed;
    width: 100vw;
    text-align: right;
    margin:0;
    padding: 0 10px;
    z-index: 2;
  }
  
  .wshopBody {
    background-color: #222;
    /* position: relative; */
    /* top: -190px; */
    max-height: 100%;
  }

  h1,h4,h5,h6,p {
    padding:0 10px;
  }
  p {
    font-size: 1.4em;
  }
  
  ul {
    font-weight: 700;
    list-style-type: '> ';
  }
  
  .imageCont {
    width: 100vw;
    height: 82vh;    
  }
  
  @media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .imageCont{
      height: 67vh;
    }

    .imageCont img {
      object-fit: cover;
      object-position: -400px 20px;
    }
    
    .offset img {
      object-fit: cover;
      object-position: -100px 25px;
    }
}